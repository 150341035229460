export const getStudentGroupsRoute = () => {
    return "/studentGroups";
}

export const getStudentGroupRoute = (studentGroupId) => {
    return `/studentGroups/${studentGroupId}`;
}

export const getStudentGroupAssignmentRoute = (studentGroupId, assignmentId) => {
    return `/studentGroups/${studentGroupId}/assignments/${assignmentId}`;
}

export const getEntityAssignmentsArchiveRoute = (entity, entityId) => {
    return `/${entity}/${entityId}/archive`;
}

export const getMyAssignmentRoute = (assignmentId) => {
    return `/myAssignments/${assignmentId}`
}

export const getMyAssignmentsRoute = () => {
    return `/myAssignments`
}

export const getMyAssignmentsArchiveRoute = () => {
    return `/myAssignments/archive`
}

export const getMyStudentsRoute = () => {
    return "/myStudents";
}
export const getMyStudentRoute = (studentId) => {
    return `/myStudents/${studentId}`;
}

export const getMyStudentAssignmentRoute = (studentId, assignmentId) => {
    return `/myStudents/${studentId}/assignments/${assignmentId}`;
}

export const getNewAssignmentRoute = (entity, entityId) => {
    return `/${entity}/${entityId}/newAssignment`
}

export const getAssignmentRoute = (entity, entityId, assignmentId) => {
    return `/${entity}/${entityId}/assignments/${assignmentId}`
}